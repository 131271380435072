import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Conversation`}</h1>
    <ul>
      <li parentName="ul">{`A conversation is the fundamental unit of consideration in `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`. It consists of a `}<a parentName="li" {...{
          "href": "/topic",
          "title": "topic"
        }}>{`topic`}</a>{`, or `}<a parentName="li" {...{
          "href": "/prompt",
          "title": "prompt"
        }}>{`prompt`}</a>{`, an optional `}<a parentName="li" {...{
          "href": "/description",
          "title": "description"
        }}>{`description`}</a>{`, an `}<a parentName="li" {...{
          "href": "/embed-code",
          "title": "embed code"
        }}>{`embed code`}</a>{` and `}<a parentName="li" {...{
          "href": "/url",
          "title": "url"
        }}>{`url`}</a>{`, and options to `}<a parentName="li" {...{
          "href": "/configuration",
          "title": "configuration"
        }}>{`configuration`}</a>{`. It produces `}<a parentName="li" {...{
          "href": "/Polis-Conversation-Data",
          "title": "Polis Conversation Data"
        }}>{`Polis Conversation Data`}</a>{``}</li>
      <li parentName="ul">{`In a `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{`, an `}<a parentName="li" {...{
          "href": "/owner",
          "title": "owner"
        }}>{`owner`}</a>{` begins by setting a `}<a parentName="li" {...{
          "href": "/topic",
          "title": "topic"
        }}>{`topic`}</a>{` or `}<a parentName="li" {...{
          "href": "/prompt",
          "title": "prompt"
        }}>{`prompt`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      